* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

html,
body {
	height: 100%;
	margin: 0;
}

.page-wrapper {
	min-height: 100%;
	margin-bottom: 0;
	margin-top: 60px;
}

.page-wrapper:after {
	content: '';
	display: block;
}

footer,
.page-wrapper:after {
	height: 0;
}

footer {
	border-top: 1px solid #e5e5e5;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 47px;
}

nav {
	display: inline-block;
	vertical-align: middle;
}

nav ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

nav ul li {
	display: inline-block;
	color: #333;
}

nav ul li.active a {
	background-color: #e1e6e9;
}

nav ul li a {
	display: inline-block;
	text-decoration: none;
	color: inherit;
	line-height: 31px;
	font-size: 12px;
	color: #787a7e;
	text-transform: uppercase;
	padding: 0 15px;
	border-radius: 3px;
}

nav ul li a:hover,
nav ul li a:focus {
	color: inherit;
}

body {
	background-color: #f8f8f8;
	font-family: 'Open Sans', sans-serif;
}

.navbar {
	height: 60px;
	background-color: #f8f8f8;
	border-top: 2px solid #07ce8f;
	text-align: center;
	box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.24);
	margin-bottom: 0;
}

.navbar .buttons {
	position: absolute;
	right: 0;
	top: 15px;
	font-weight: 400;
	text-transform: uppercase;
}

.navbar .buttons .order,
.navbar .buttons .login {
	line-height: 30px;
	color: #fff;
	padding: 0 10px;
	font-size: 12px;
}

.navbar .buttons .order a,
.navbar .buttons .login a {
	color: inherit;
}

.navbar .buttons .order {
	background-color: #00b7e5;
	border-radius: 4px 0 0 4px;
	margin-right: 1px;
}

.navbar .buttons .login {
	background-color: #00e57f;
	border-radius: 0 4px 4px 0;
}

.navbar nav ul {
	line-height: 58px;
}

.navbar .home {
	position: absolute;
	left: 0;
	top: 0;
}

.navbar a {
	text-decoration: none;
	display: inline-block;
	vertical-align: middle;
}

.navbar h1 {
	font-size: 25px;
	color: #00bd80;
	margin: 0;
	line-height: 56px;
	font-weight: 600;
}

.dig-bick {
	background-image: url('../img/backgrounds/main.png');
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center;
	text-align: center;
	color: #fff;
	-webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.dig-bick p {
	font-size: 20px;
	margin: 3px 0 0 0;
	color: #fff;
	line-height: 32px;
	max-width: 860px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;
	margin-bottom: 2em;
}

.dig-bick h1 {
	font-size: 30px;
	font-weight: 600;
	line-height: 38px;
	margin: auto;
	margin-top: 30px;
	padding: 40px;
	color: #fff;
	max-width: 960px;
}

.dig-bick .buttons .btn {
	background: transparent;
	border: 2px solid #fff;
	color: #fff;
	font-size: 18px;
	line-height: 30px;
	font-weight: 400;
	text-transform: uppercase;
}

.dig-bick .buttons .btn:hover {
	background: rgba(0, 0, 0, 0.5);
}

.dig-bick .slideshow-wrapper {
	width: 843px;
	height: 488px;
	display: inline-block;
	position: relative;
	margin-bottom: -100px;
}

.dig-bick .slideshow-wrapper .iphone {
	width: 148px;
	height: 271px;
	background-image: url('../img/index_iphone.png');
	position: absolute;
	left: -75px;
	bottom: 5px;
}

.dig-bick .slideshow-wrapper .router {
	width: 167px;
	height: 239px;
	background-image: url('../img/index_router-beacon.png');
	position: absolute;
	right: -75px;
	bottom: 15px;
}

.dig-bick .slideshow {
	background-image: url('../img/macbook_bg.png');
	width: 843px;
	height: 488px;
	display: inline-block;
	padding: 28px 104px 63px;
	position: relative;
}

.dig-bick .slideshow .slides {
	width: 635px;
	height: 397px;
	background-color: #222;
	position: relative;
}

.dig-bick .slideshow .slides:before {
	content: '';
	width: 366px;
	height: 263px;
	background-image: url('../img/macbook_glare.png');
	position: absolute;
	left: -19px;
	top: -25px;
	pointer-events: none;
}

section {
	width: 980px;
	margin: 0 auto;
	padding-top: 30px;
}

.wrapper {
	width: 980px;
	margin: 0 auto;
	position: relative;
}

h2 {
	font-size: 40px;
	font-weight: 300;
	margin: 0;
	color: #515b62;
}

p.subheader {
	font-size: 20px;
	color: #919090;
	margin: 10px 0 30px;
	line-height: 32px;
	max-width: 820px;
	margin-left: auto;
	margin-right: auto;
}

hr {
	width: 1020px;
	margin: 0 auto;
	border-top-color: #e5e5e5;
}

p.press {
	width: 960px;
	margin-top: 130px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
}

p.press > * {
	display: inline-block;
	vertical-align: middle;
	margin: 15px 30px;
}

.prices {
	width: 769px;
	margin-right: -26px;
	margin-left: auto;
	margin-right: auto;
}

.prices .plan {
	width: 230px;
	height: 480px;
	margin-right: 26px;
	background-color: #fff;
	float: left;
	padding: 0 20px 30px;
	border-radius: 4px;
	position: relative;
	margin-top: 130px;
	text-align: center;
}

.prices .plan,
.prices .plan:before {
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.prices .plan:hover,
.prices .plan:hover:before {
	-webkit-box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
	-moz-box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
	box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}

.prices .plan:before {
	content: '';
	width: 210px;
	height: 105px;
	background-color: #fff;
	border-radius: 105px 105px 0 0;
	display: inline-block;
	position: absolute;
	left: 50%;
	top: -105px;
	margin-left: -104px;
}

.prices .plan img {
	margin-top: -105px;
	margin-left: -10px;
	display: inline-block;
	position: relative;
	z-index: 2;
	border-radius: 100%;
}

.prices .plan h3 {
	font-size: 22px;
	color: #515b62;
	font-weight: normal;
}

.prices .plan p {
	font-size: 14px;
	color: #919090;
}

.prices .plan .price {
	font-size: 42px;
	font-weight: bold;
	color: #14ba28;
	margin: 0;
	position: absolute;
	bottom: 90px;
	left: 0;
	width: 100%;
	line-height: 58px;
}

.prices .plan .price span {
	color: #919090;
	font-size: 12px;
	line-height: 12px;
	display: inline-block;
	vertical-align: baseline;
	font-weight: normal;
}

.prices .plan a {
	width: 140px;
	font-size: 14px;
	color: #fff;
	background-color: #1bdb8c;
	line-height: 40px;
	text-align: center;
	display: inline-block;
	text-transform: uppercase;
	text-decoration: none;
	border-radius: 3px;
	position: absolute;
	bottom: 30px;
	left: 50%;
	margin-left: -70px;
}

.prices .plan a:after {
	content: '';
	width: 6px;
	height: 10px;
	display: inline-block;
	background-image: url('../img/rarr.gif');
	margin-left: 10px;
}

.center {
	text-align: center;
}

.index_solutions h2 {
	font-size: 40px;
}

.index_solutions .row {
	border-bottom: 1px solid #e5e5e5;
	margin-top: 60px;
	padding-bottom: 60px;
}

.index_solutions .row p {
	font-size: 18px;
	color: #919090;
	line-height: 28px;
	max-width: 620px;
}

.index_solutions .row h3,
.index_solutions .row p {
	max-width: 620px;
	margin-left: auto;
	margin-right: auto;
}

.index_solutions .row img {
	display: inline-block;
}

.right {
	text-align: right;
}

.left {
	text-align: left;
}

.jumbotron {
	background-image: url('../img/jumbotron-bg.png');
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center;
	color: #fff;
	text-align: center;
	padding-top: 30px;
	margin-bottom: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.jumbotron p {
	color: #fff;
	width: 800px;
	margin: 0 auto;
}

.jumbotron h2 {
	margin-top: 0;
	font-size: 24px;
	font-weight: 600;
	color: #fff;
	max-width: 940px;
	margin: auto;
}

.jumbotron h1 {
	font-size: 48px;
	font-weight: 600;
}

.jumbotron.network {
	background-image: url('../img/network-bg.jpg');
}

h2 {
	font-size: 22px;
	font-weight: normal;
	color: #515b62;
	margin-bottom: 25px;
	margin-top: 70px;
}

p {
	font-size: 18px;
	color: #515b62;
}

ul.list {
	list-style-type: none;
	padding: 0;
}

ul.list li {
	font-size: 18px;
	color: #515b62;
	background-image: url('../img/bullet.png');
	background-position: 0px 9px;
	background-repeat: no-repeat;
	padding-left: 22px;
	margin-bottom: 15px;
}

.analytics .answers {
	background-image: url('../img/answers-bg.png');
	background-repeat: no-repeat;
	background-position: center center;
	border-bottom: 1px solid #ccc;
}

.analytics .answers h2 {
	text-align: center;
	width: 100%;
	font-size: 1.2em;
}

.analytics .answers ul {
	margin-top: 0;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	list-style-type: none;
}

.analytics .answers ul li {
	font-size: 18px;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: left;
	color: rgba(0, 0, 0, 0.5);
	background-image: url('../img/li-check.png');
	background-position: top left;
	background-repeat: no-repeat;
	padding-left: 27px;
}

.analytics .row.rates {
	margin-top: 20px;
	margin-bottom: 40px;
}

.analytics .row.metrics {
	margin-bottom: 60px;
}

.analytics .row.segments {
	border-bottom: 1px solid #ccc;
}

.analytics .row.segments p {
	font-size: 20px;
	line-height: 28px;
	color: #515b62;
	font-weight: 100;
	text-align: left;
}

.analytics .row.segments .col-md-5 p,
.analytics .row.segments .col-md-4 p {
	margin-top: 56px;
	padding-bottom: 40px;
}

.analytics .row.segments .col-md-7,
.analytics .row.segments .col-md-8 {
	text-align: center;
}

.analytics .row.features,
.analytics h2 {
	margin-top: 40px;
	background-repeat: no-repeat;
	display: inline-block;
}

.analytics .row.features.eye,
.analytics h2.eye {
	background-image: url('../img/eye.png');
	background-position: 0 3px;
}

.analytics .row.features.detail,
.analytics h2.detail {
	background-image: url('../img/micro.png');
	padding-left: 33px;
}

.analytics .row.features.social,
.analytics h2.social {
	background-image: url('../img/ft.png');
	padding-left: 66px;
}

.analytics .col-md-3 {
	text-align: center;
}

.analytics .col-md-3 .wrap {
	background-color: #fff;
	display: inline-block;
	min-height: 178px;
	padding: 5px 15px;
	margin-bottom: 20px;
}

.analytics .col-md-3 p {
	font-size: 16px;
	color: #919090;
}

.analytics .col-md-3 h3 {
	font-size: 22px;
	font-weight: 600;
	color: #07ce8f;
}

.analytics .col-md-6 {
	text-align: center;
}

.analytics .col-md-6.detail .a {
	margin: 20px 40px;
}

.analytics .col-md-6.detail .a span {
	margin: 75px -50px 15px;
	color: #949494;
	display: inline-block;
}

.analytics .col-md-6.social .a {
	display: block;
	margin-bottom: -10px;
	margin-top: 28px;
	margin-left: 30px;
}

.analytics .col-md-6.social .a span {
	display: inline-block;
	width: 220px;
	text-align: left;
	margin-left: 76px;
	line-height: 58px;
	color: #949494;
}

.col-md-4 .feature {
	width: 100%;
	margin: 10px;
	border-radius: 5px;
	height: 183px;
	margin-top: 80px;
	text-align: center;
	max-width: 595px;
	margin-left: auto;
	margin-right: auto;
	background-color: #fff;
}

.col-md-4 .feature h3 {
	color: #fff;
	font-size: 20px;
	widht: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	padding-bottom: 10px;
	padding-top: 40px;
	margin-top: 20px;
	margin-bottom: 10px;
	font-weight: 800;
}

.col-md-4 .feature h3 span {
	font-weight: 400;
	font-size: 18px;
}

.col-md-4 .feature .description {
	font-size: 16px;
	line-height: 18px;
	padding: 20px;
	color: rgba(255, 255, 255, 0.8);
}

.col-md-4 .feature img.icon {
	width: 100px;
	height: 100px;
	position: absolute;
	margin-right: auto;
	margin-left: -50px;
	margin-top: -70px;
}

.btn-primary.btn-lg {
	font-size: 22px;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	background-color: #00e57f;
	text-transform: uppercase;
	border-color: #2cd5a0;
	border-radius: 0;
}

.blueshit {
	margin-top: 30px;
	margin-left: -30px;
	margin-right: -30px;
	padding-bottom: 50px;
	overflow: hidden;
	margin: 0;
	margin-bottom: -50px;
	background: #bde2ef;
	/* Old browsers */
	background: -moz-linear-gradient(top, #bde2ef 0%, #f8f8f8 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #bde2ef),
		color-stop(100%, #f8f8f8)
	);
	/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #bde2ef 0%, #f8f8f8 100%);
	background: -o-linear-gradient(top, #bde2ef 0%, #f8f8f8 100%);
	background: -ms-linear-gradient(top, #bde2ef 0%, #f8f8f8 100%);
	background: linear-gradient(to bottom, #bde2ef 0%, #f8f8f8 100%);
}

.blueshit h2 {
	margin-top: 40px;
	margin-bottom: 90px;
	color: #eee;
}

.network .center img {
	margin: 0 -1000px 45px;
}

.network .col-md-12 {
	margin-bottom: 60px;
}

.network .col-md-12 .list {
	width: 800px;
	margin: 0 auto;
}

.ibeacon .row {
	margin: 80px 0;
}

.hotspots {
	margin: 50px 0px;
}

.hotspots .col-md-7,
.hotspots .col-md-5 {
	height: 250px;
}

.hotspots .col-md-7 {
	background-color: #fff;
}

.hotspots .col-md-4 .wrap {
	width: 250px;
	height: 250px;
	background-color: #fff;
	text-align: center;
}

.hotspots .col-md-4 .wrap h3 {
	margin: 0 auto;
	font-size: 18px;
	font-weight: 400;
	width: 200px;
	padding-top: 45px;
	color: #515b62;
}

.hotspots .col-md-4 .wrap h3 span {
	display: block;
	font-size: 70px;
	color: #fff;
	background-color: #07ce8f;
	width: 150px;
	line-height: 75px;
	border-radius: 4px;
	margin: 0 auto;
}

.hotspots .col-md-4 .wrap h3 span small {
	font-size: 18px;
	color: inherit;
}

.hotspots .col-md-7 {
	padding: 40px;
}

.hotspots .col-md-7 img {
	float: left;
	margin: 0 30px 0 0;
}

.hotspots .col-md-7 h3 {
	font-size: 36px;
	font-weight: 200;
	margin-top: 57px;
}

form input,
form button {
	border-radius: 4px;
}

form input {
	width: 490px;
	height: 70px;
	border: 2px solid #07ce8f;
	font-size: 22px;
	text-indent: 10px;
}

form *[type='submit'] {
	border: none;
	outline: none;
	width: 209px;
	height: 70px;
	background-color: #f8ab48;
	color: #fff;
	text-transform: uppercase;
	position: relative;
	top: -4px;
}

p.promise {
	font-size: 13px;
	color: #afb5ba;
}

.networkshits {
	margin-bottom: 40px;
	margin-top: 40px;
}

.networkshits .feature {
	height: 140px;
}

.networkshits .feature .description {
	color: #515b62;
	line-height: 1.3;
	padding: 35px 20px 20px;
	font-size: 18px;
}

@media (max-width: 990px) {
	.analytics .col-md-4 {
		padding-bottom: 20px;
	}

	.analytics .row.segments p {
		text-align: center;
	}
}

.ibeacons-bg {
	margin-bottom: 30px;
	overflow: hidden;
	background-image: url('../img/ibeacon-bg.png');
	height: 565px;
	background-position: center bottom;
	background-repeat: no-repeat;
}

.ibeacons-bg h2 {
	margin-top: 45px;
}

.ibeacons-bg p.desc {
	width: 396px;
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 30px;
	padding: 35px;
	font-size: 18px;
	color: #515b62;
	margin: 130px auto 0;
}

.ibeacons-features {
	margin: 40px auto;
}

.ibeacons-features .col-md-6 {
	padding: 8px;
}

.ibeacons-features .col-md-6 .feature {
	background-color: #fff;
	padding: 20px;
	position: relative;
	height: 200px;
}

.ibeacons-features .col-md-6 .feature:before {
	content: '';
	display: inline-block;
	height: 60%;
	vertical-align: middle;
	margin-right: -0.25em;
	/* Adjusts for spacing */
}

.ibeacons-features .col-md-6 .img-wrapper {
	width: 100px;
	text-align: center;
	display: inline-block;
}

.ibeacons-features .col-md-6 img {
	display: inline-block;
	vertical-align: middle;
}

.ibeacons-features .col-md-6 p {
	display: inline-block;
	vertical-align: middle;
	font-size: 18px;
	line-height: 1.3;
	color: #515b62;
	width: 400px;
}

.greenbg {
	background-color: #2cd5a0;
	padding-top: 60px;
}

.greenbg p {
	color: #fff;
	font-size: 30px;
	max-width: 850px;
	margin: 0 auto;
}

.team .col-md-2 img {
	width: 100%;
}

.white-bg {
	background-color: #fff;
	padding-bottom: 30px;
}

table.comparison {
	background: #fff;
}

table.comparison tbody td {
	font-size: 16px;
	color: #515b62;
	border-top: none !important;
	border-right: 1px solid #e5e5e5;
}

table.comparison th {
	color: #515b62;
	font-weight: 600;
	border-bottom: 1px solid #e5e5e5 !important;
	border-top: none;
}

.answer {
	background: #fff;
}

.container {
	max-width: 980px;
}

.plans .plan {
	background: #fff;
	padding-bottom: 20px;
	margin-bottom: 30px;
}

.plans .plan .description {
	padding-left: 60px;
}

.plans .plan .description h3 {
	color: #24d17d;
}

.plans .plan .description ul.features {
	list-style-type: none;
	padding-left: 0;
}

.plans .plan .description ul.features li {
	font-size: 16px;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: left;
	color: #464646;
	background-image: url('../img/li-check.png');
	background-position: top left;
	background-repeat: no-repeat;
	padding-left: 27px;
}

.plans .plan .body .price {
	border-radius: 50px;
	border: 1px solid #d1f3e3;
	width: 564px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 40px;
	padding-top: 10px;
	padding-bottom: 20px;
}

.plans .plan .body .price .item {
	display: inline-block;
}

.plans .plan .body .price .item .sub {
	font-size: 14px;
	color: #9f9f9f;
	text-align: center;
}

.plans .plan .body .price .item .main {
	color: #434242;
	font-size: 27px;
	text-align: center;
}

.plans .plan .header {
	margin: 0;
	padding: 0;
	text-align: center;
}

.plans .plan .header h1 {
	font-size: 30px;
	line-height: 50px;
	color: #fff;
	margin: 0;
}

.plans .plan .header h2 {
	font-size: 14px;
	line-height: 0;
	text-align: center;
	margin: 0;
	padding: 20px;
	color: #fff;
}

h1 {
	margin: 60px;
}

.black-bg {
	background: #000;
	padding: 40px;
}

.black-bg h1 {
	color: #fff;
}
.carousel.carousel-slider {
	width: 100%;
	box-shadow: 0 0 1px 1px #00000030;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
	border: 2px solid #515b624f !important;
}

.carousel .thumb {
	transition: border 0.15s ease-in;
	display: inline-block;
	margin-right: 6px;
	white-space: nowrap;
	overflow: hidden;
	padding: 2px;
	border: 1px solid #515b6221 !important;
}

.analytics .row.segments p {
	display: none;
}

.boxLaflet {
	width: 100%;
	height: 500px;
	/*padding-bottom: 10px;*/
	padding-top: 10px;
}
.boxLafletMaps {
	height: 100%;
	width: 100%;
}

.containerContent {
	width: 100%;
	height: max-content;
	display: grid;
	grid-auto-rows: max-content;
	/*grid-template-columns: max-content;*/
	/*justify-items: center;*/
}

.containerCenterChild {
	display: grid;
	justify-items: center;
}
